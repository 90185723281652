<template>
  <div>
    <SectionHeading
      :title="$t('views.marketing.promotions.title')"
      iconName="OfficeBuildingIcon"
      subTitle="Manage your promotions"
    />

    <!-- ACTIONS -->
    <div class="mt-10 flex justify-start flex-wrap items-center">
      <SecondaryButton @click="refreshData">
        <DynamicIconSolid
          icon="RefreshIcon"
          class="flex-shrink-0 h-5 w-5 mr-2 text-gray-700"
          aria-hidden="true"
        />
        Refresh Data
      </SecondaryButton>

      <Label value="Rodzaj promocji" class="m-2" />
      <TRichSelect
        @input="promotionTypeHandler($event.detail)"
        :options="promotionTypeList"
        valueAttribute="value"
        textAttribute="label"
        placeholder="Choose"
        multiple
        class="w-1/12"
      />

      <DatePicker
        :key="range.start"
        :popover="{ visibility: 'click' }"
        v-model="range"
        is-range
        class="ml-2 mr-2"
      >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <div class="flex flex-col sm:flex-row justify-start items-center">
            <div class="block text-sm font-medium text-gray-700 mr-2">
              Range
            </div>
            <div class="relative flex-grow">
              <svg
                class="
                  text-gray-600
                  w-4
                  h-full
                  mx-2
                  absolute
                  pointer-events-none
                "
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                ></path>
              </svg>
              <input
                class="
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  py-2.5
                  text-left
                  cursor-default
                  focus:outline-none
                  sm:text-sm
                  flex-grow
                  pl-8
                  pr-2
                  w-full
                "
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start"
                v-on="inputEvents.start"
              />
            </div>
            <span class="flex-shrink-0 m-2">
              <svg
                class="w-4 h-4 stroke-current text-gray-600"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
            <div class="relative flex-grow">
              <svg
                class="
                  text-gray-600
                  w-4
                  h-full
                  mx-2
                  absolute
                  pointer-events-none
                "
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                ></path>
              </svg>
              <input
                class="
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  py-2.5
                  text-left
                  cursor-default
                  focus:outline-none
                  sm:text-sm
                  flex-grow
                  pl-8
                  pr-2
                  w-full
                "
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.end"
                v-on="inputEvents.end"
              />
            </div>
            <span
              class="flex-shrink-0 m-2 cursor-pointer"
              v-if="inputValue.end"
              @click="resetRange"
            >
              <DynamicIconSolid
                icon="XCircleIcon"
                class="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            </span>
          </div>
        </template>
      </DatePicker>

      <div class="justify-self-end ml-auto mr-0 flex align-center space-x-4">
        <router-link
          :to="{
            name: 'PromotionsAddEdit',
            params: { id: 'new' },
          }"
        >
          <Button>
            <DynamicIconSolid
              icon="PlusIcon"
              class="mr-2 -ml-1 h-5 w-5"
              aria-hidden="true"
            />
            New Promotion
          </Button>
        </router-link>
        <Select
          label="Per page"
          :dataList="itemsPerPageArray"
          @valueChanges="perPageHandler($event)"
        />
      </div>
    </div>

    <!-- PROMOTIONS DATA -->
    <div class="flex flex-col sm:-mx-6 lg:-mx-8">
      <div class="my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
              relative
            "
          >
            <LoadingSpinner v-if="promotionsData.loading" />
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="customers-list__col flex items-center">
                    <div class="flex items-center">
                      <span>ID</span>
                      <Sort
                        keyValue="id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'id'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Nazwa</span>
                      <Sort
                        keyValue="name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'name'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Kod</span>
                      <Sort
                        keyValue="identifier"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'identifier'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Priorytet</span>
                      <Sort
                        keyValue="priority"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'priority'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Rodzaj</span>
                      <Sort
                        keyValue="type"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'type'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Wartość zniżki</span>
                      <Sort
                        keyValue="discount_value"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'discount_value'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Od</span>
                      <Sort
                        keyValue="starts_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'starts_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Do</span>
                      <Sort
                        keyValue="ends_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'ends_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="relative customers-list__col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in promotionsData.data"
                  :key="item.id"
                  :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.id }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.name }}
                  </td>
                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.identifier }}
                  </td>
                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.priority }}
                  </td>
                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.type }}
                  </td>

                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.discount_value }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.starts_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.ends_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-center text-sm text-gray-500
                    "
                  >
                    <div class="flex space-x-6 justify-end">
                      <router-link
                        :to="{
                          name: 'PromotionsAddEdit',
                          params: { id: item.id },
                        }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        <DynamicIconSolid
                          icon="PencilIcon"
                          class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                          aria-hidden="true"
                        />
                      </router-link>
                      <DynamicIconSolid
                        icon="TrashIcon"
                        class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                        aria-hidden="true"
                        @click="removePromotion(item)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- PAGINATION -->
    <Pagination @changePage="changePage($event)" :meta="promotionsData.meta" />

    <!-- DELETE PROMOTION -->
    <PromotionsDelete
      :modalOpen="removePromotionModal"
      :promotionData="promotionToRemove"
      :urlParams="config"
      @closeDeleteChanges="removePromotionModal = false"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, getCurrentInstance, watch } from 'vue';
import { useStore } from 'vuex';
import Pagination from '@/components/@globals/helpers/Pagination';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import Sort from '@/components/table/Sort';
import PromotionsDelete from './PromotionsDelete.vue';
import { TRichSelect } from '@variantjs/vue';
import Select from '@/components/select/Select';
import Label from '../../../components/@globals/helpers/Label';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import Button from '../../../components/@globals/helpers/Button';
import { PROMOTION_TYPE_LIST } from './promotion-constants';

export default {
  name: 'PromotionsList',

  components: {
    SecondaryButton,
    Pagination,
    LoadingSpinner,
    Sort,
    PromotionsDelete,
    TRichSelect,
    Select,
    Label,
    Button,
  },

  setup() {
    const instance = getCurrentInstance();
    const dayjs = instance.appContext.config.globalProperties.$dayjs;
    const range = ref({
      start: null,
      end: null,
    });
    const currentSort = ref('');
    const store = useStore();
    let config = reactive({
      page: 1,
      per_page: 5,
      silent: false,
    });
    const itemsPerPageArray = [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 15 },
      { id: 4, value: 20 },
    ];

    store.dispatch('getPromotions', config);
    const promotionsData = computed(() => store.getters.getPromotions);

    const promotionTypeFilter = ref([]);
    const promotionTypeList = PROMOTION_TYPE_LIST;

    function refreshData() {
      resetRange();
      store.dispatch('getPromotions', {
        page: 1,
        per_page: 5,
      });
    }

    function changePage(page) {
      config.page = page;
      store.dispatch('getPromotions', config);
    }

    function perPageHandler(payload) {
      config.per_page = payload;
      store.dispatch('getPromotions', config);
    }

    watch(
      () => range.value,
      (currentValue) => {
        if (currentValue.start != null && currentValue.end != null) {
          config = {
            ...config,
            starts_at:
              dayjs(currentValue.start).format('YYYY-MM-DD') + ' 00:00:00',
            ends_at: dayjs(currentValue.end).format('YYYY-MM-DD') + ' 23:59:59',
          };
        } else {
          delete config.starts_at;
          delete config.ends_at;
        }
        store.dispatch('getPromotions', config);
      },
      { deep: true }
    );

    function resetRange() {
      range.value.start = null;
      range.value.end = null;
    }

    function promotionTypeHandler(payload) {
      if (payload.length) {
        config = { ...config, type: payload };
      } else {
        delete config.type;
      }
      store.dispatch('getPromotions', config);
    }

    function sortHandler(payload) {
      currentSort.value = payload.key;
      if (payload.sort != null) {
        config = { ...config, sort: payload.sort };
      } else {
        delete config.sort;
      }
      store.dispatch('getPromotions', config);
    }

    const removePromotionModal = ref(false);
    const promotionToRemove = ref(null);
    function removePromotion(item) {
      promotionToRemove.value = item;
      removePromotionModal.value = true;
    }

    return {
      promotionsData,
      currentSort,
      removePromotionModal,
      promotionToRemove,
      changePage,
      sortHandler,
      removePromotion,
      config,
      refreshData,
      promotionTypeHandler,
      promotionTypeList,
      promotionTypeFilter,
      itemsPerPageArray,
      perPageHandler,
      dayjs,
      range,
      resetRange,
    };
  },
};
</script>
