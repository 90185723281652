<template>
  <ConfirmationModal :show="toggleModal" @close="closeDeleteHandler()">
    <template #title>Potwierdź</template>

    <template #content>
      Czy na pewno chcesz usunąć magazyn<br />
      <strong>{{ depotData.identifier }} </strong>?
    </template>

    <template #footer>
      <SecondaryButton @click="closeDeleteHandler()"> Anuluj</SecondaryButton>

      <DangerButton class="ml-2" @click="removeDepotAction()">
        Usuń
      </DangerButton>
    </template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '../../../components/@globals/helpers/ConfirmationModal';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import DangerButton from '../../../components/@globals/helpers/DangerButton';
import DepotService from '@/service/depots/depots.js';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'DepotDelete',
  props: {
    depotData: Object,
    urlParams: Object,
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SecondaryButton,
    DangerButton,
  },
  emits: ['closeDeleteChanges'],
  setup(props, { emit }) {
    const store = useStore();
    const toggleModal = ref(props.modalOpen);

    watch(
      () => props.modalOpen,
      (val) => {
        toggleModal.value = val;
      }
    );

    function closeDeleteHandler() {
      toggleModal.value = false;
      emit('closeDeleteChanges', false);
    }

    function removeDepotAction() {
      DepotService.deleteDepot(props.depotData.id).then(
        () => {
          let silentConfig = { ...props.urlParams, silent: true };
          store.dispatch('getDepots', silentConfig);
          store.commit('setAppNotifications', {
            title: 'Magazyn usunięty',
            content: 'Pomyslnie usunieto magazyn',
            type: 'success',
            autoclose: true,
          });
          closeDeleteHandler();
        },
        () => {
          store.commit('setAppNotifications', {
            title: 'Nie udało się usunąć magazynu',
            content: '',
            type: 'error',
            autoclose: true,
          });
          closeDeleteHandler();
        }
      );
    }

    return {
      toggleModal,
      removeDepotAction,
      closeDeleteHandler,
    };
  },
};
</script>
